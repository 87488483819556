<script>
import { Pie } from "vue-chartjs";

import { mapState } from "vuex";

export default {
  extends: Pie,

  mounted() {
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");
    setTimeout(() => {
      this.renderChart(
        {
          labels: ["Alto", "Medio", "Bajo"],
          datasets: [
            {
              backgroundColor: [this.gradient, this.gradient2, "#32e493"],
              data: [
                this.PrioridadAlta,
                this.PrioridadMedia,
                this.PrioridadBaja,
              ],
            },
          ],
        },
        { responsive: true, maintainAspectRatio: false }
      );
    }, 2000);
  },
  computed: {
    ...mapState(["PrioridadAlta", "PrioridadMedia", "PrioridadBaja"]),
  },
};
</script>
