<script>
import { Bar } from "vue-chartjs";
import { mapState } from "vuex";

export default {
  extends: Bar,
  mounted() {
    setTimeout(() => {
      this.renderChart(
        {
          labels: this.Areas,
          datasets: [
            {
              label: "Abiertos",
              backgroundColor: "#f87979",
              data: this.Abiertos,
            },
            {
              label: "Solucionados",
              backgroundColor: "#a7f3d0",
              data: this.Cerrados,
            },
          ],
        },
        { responsive: true, maintainAspectRatio: false }
      );
    }, 2000);
  },
  computed: {
    ...mapState(["Areas", "Cerrados", "Abiertos"]),
  },
};
</script>
